import { env } from '../env'
import isValidDomain from 'is-valid-domain'
import { useTheme } from 'next-themes'
import router from 'next/router'
import { useState, useEffect } from 'react'
import toast from 'react-hot-toast'

export { refreshSsr } from 'beskar/src/utils'

export function validateRouteTargetUrl(x: string) {
    if (!x.startsWith('http://') && !x.startsWith('https://')) {
        return 'Must be an url'
    }
}

export function nDaysAgo(n: number) {
    const d = new Date()
    d.setDate(d.getDate() - n)
    return d
}

export async function fetchWithTimeout(
    resource,
    { timeout = 2000, ...options }: RequestInit & { timeout?: number } = {},
) {
    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(), timeout)
    const response = await fetch(resource, {
        ...options,
        signal: controller.signal,
    })
    clearTimeout(id)
    return response
}

export function partition<T>(arr: T[], key: (x: T) => boolean) {
    const a = [] as T[]
    const b = [] as T[]
    for (const x of arr) {
        if (key(x)) {
            a.push(x)
        } else {
            b.push(x)
        }
    }
    return [a, b]
}

export function isValidDomainWithPort(completeDomain: string) {
    // remove port if present
    completeDomain = completeDomain.replace(/:\d+$/, '')
    return isValidDomain(completeDomain)
}

import { loadStripe, Stripe } from '@stripe/stripe-js'
import { EmailCampaignStep } from 'db'
import React from 'react'
import { renderTemplate } from 'warmup/src/liquid'

let stripePromise: Promise<Stripe | null>

export const getStripe = () => {
    if (!stripePromise) {
        stripePromise = loadStripe(env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || '')
    }

    return stripePromise
}

export function campaignUrl({ orgId, campId }) {
    return `/board/org/${orgId}/camp/${campId}`
}

export function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/
    if (!re.test(email)) {
        return 'Invalid email'
    }
}

export function validateEmailStep(step: EmailCampaignStep) {
    if (step.index !== 0 && !step.afterDays) {
        return 'Step does not have after how many days'
    }
    if (!step.subject) {
        return 'Subject is required'
    }
    if (!step.body) {
        return 'Body is required'
    }
    if (!step.campId) {
        return 'Campaign id is required'
    }
    if (!step.variantId) {
        return 'Step variant id is required'
    }
    renderTemplate({ template: step.body, fields: { account: {} } })
    renderTemplate({ template: step.subject, fields: { account: {} } })
}

export function sleep(n) {
    return new Promise((resolve) => setTimeout(resolve, n))
}

export function isObjEmpty(obj) {
    if (!obj) {
        return true
    }
    for (let k in obj) {
        return false
    }
    return true
}

export async function downloadGooGleSheetAsCsv(url: string) {
    if (!url) {
        return ''
    }
    const u = new URL(url)
    let id = ''
    let parts = u.pathname.split('/').filter((x) => x)
    for (let [i, part] of parts.entries()) {
        if (part === 'd') {
            id = parts[i + 1]
        }
    }
    if (!id) {
        console.error('Could not find sheets id in url', url)
        return ''
    }
    const sheetId = '0'

    let downloadableUrl = `https://docs.google.com/spreadsheets/d/${id}/gviz/tq?tqx=out:csv&gid=${sheetId}`
    if (!url) {
        return
    }
    const res = await fetch(downloadableUrl, {
        headers: {},
    })
    const text = await res.text()

    return text
}

export function getUsageWindowStartDate() {
    const now = new Date()
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
    return firstDay
}

export function useDebouncedEffect(callback, deps = [], delay = 120) {
    const data = React.useRef({ firstTime: true, clearFunc: null })
    React.useEffect(() => {
        const { firstTime, clearFunc } = data.current

        if (firstTime) {
            data.current.firstTime = false
            return
        }

        const handler = setTimeout(() => {
            if (clearFunc && typeof clearFunc === 'function') {
                clearFunc()
            }
            data.current.clearFunc = callback()
        }, delay)

        return () => {
            clearTimeout(handler)
        }
    }, [delay, ...deps])
}

export function parseEmailWithName(str: string) {
    if (!str.includes('@')) {
        return { name: '', email: '' }
    }
    const parts = str.split('<')
    if (parts.length === 1) {
        return { name: '', email: str }
    }
    const name = parts[0].trim()
    const email = parts[1].replace('>', '').trim()
    return { name, email }
}

export function groupBy<T>(arr: T[], key: (x: T) => string) {
    const map: Record<string, T[]> = {}
    for (const x of arr) {
        const k = key(x)
        if (!map[k]) {
            map[k] = []
        }
        map[k].push(x)
    }
    return map
}
