import { SendingMailAccount } from 'db'
import {
    assert,
    Context,
    Emitter,
    Liquid,
    TagImplOptions,
    TagToken,
    Token,
    Tokenizer,
    TokenKind,
    TopLevelToken,
    Value,
    _evalToken,
} from 'liquidjs'
import { argumentsToValue } from 'liquidjs/dist/util/underscore'
const engine = new Liquid({})

const random = (v: any) => {
    // v = v.valueOf()
    // console.log('random', v)

    if (Array.isArray(v)) {
        return randomPitch(v)
    } else {
        return randomPitch(
            v
                .split(',')
                .map((s) => s.trim())
                .filter(Boolean),
        )
    }
}

function randomPitch<T>(arr: T[]): T {
    return arr[Math.floor(Math.random() * arr.length)] || ('' as any)
}

engine.registerFilter('random', random)

export function renderTemplate({
    template: templateString = '',
    fields,
}: {
    template: string
    fields: {
        account: Partial<SendingMailAccount>
        [key: string]: any
    }
}) {
    templateString = templateString || ''
    const template = engine.parse(templateString)

    const { email, firstName, lastName } = fields.account || {}

    fields = {
        ...fields,
        account: {
            email,
            firstName,
            lastName,
        },
    }

    let variables = new Set(
        template
            .map(({ token }: { token: Token }) => {
                try {
                    // console.log('token', token.constructor.name, token.getText())

                    let str = ''
                    if (token?.kind === TokenKind.Output) {
                        str = token['content']
                    } else if (token?.kind === TokenKind.Tag) {
                        // TODO variables inside tags (like if) are not parsed as variables
                        // console.log('tag', token)
                        str = token['args']
                    } else {
                        return
                    }
                    if (!str) {
                        return
                    }

                    const tokenizer = new Tokenizer(
                        str,
                        engine.options.operatorsTrie,
                    )
                    const identifier = tokenizer.readIdentifier()
                    // console.log('identifier', identifier)
                    const variable = identifier.content || ''
                    // skip if it's a number
                    if (variable && variable.match(/^\d+$/)) {
                        return ''
                    }
                    return variable || ''
                } catch (e) {
                    console.error('error getting variables', e)
                    return ''
                }
            })
            .filter((item) => item),
    )
    // console.log('variables', variables)
    const string = engine.renderSync(template, fields, {})
    // console.log('string', string)
    return { string, variables: [...variables] }
}
