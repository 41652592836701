import { EmailReceived, EmailSent, SendingMailAccount } from 'db'
import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

export const atomUpgradeModal = atom({
    isOpen: false,
    // orgId: '',
    reason: '',
})
export const atomSendEmail = atom<{
    fromAccountId?: string
    replyTo?: EmailReceived | EmailSent
    isOpen?: boolean
    subject?: string
    html?: string
    text?: string
    toEmail?: string
}>({
    isOpen: false,
    subject: '',
    text: '',
    toEmail: '',
})

export const atomExternalSendingEmailModal = atom<{
    isOpen?: boolean
    account?: SendingMailAccount
}>({
    isOpen: false,
})

export const atomMailAccountSetting = atom<{
    isOpen?: boolean
    account?: SendingMailAccount
}>({
    isOpen: false,
})

export const chooseMailTypeAtom = atom<{ isOpen?: boolean }>({})

export const atomDNSModal = atomWithStorage<{
    isOpen: boolean
    account?: SendingMailAccount
}>('atomDNSModal', {
    isOpen: false,
})

export const atomInternalSendingEmailModal = atom<{
    isOpen?: boolean
    account?: SendingMailAccount
}>({
    isOpen: false,
})
